import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Progress } from "antd";
import useMedia from "use-media";
import * as S from "./styles";
import {
  CupIcon,
  CircleCheckIcon,
  BookIcon,
  ProfileIcon,
  GameIcon,
  BlastLogoGradient,
  LogoBlast,
  LogoIcon,
  BlastIcon,
} from "../../../../assets/svg";
// import { useAppState } from "../../../../providers/AppStateProvider";
import { breakpoints } from "../../../../themes";
import callAxios from "../../../../hooks/Axios";
import { ENDPOINT } from "../../../../constants/endpoints";
import Flex from "../../common/Flex";
import { Button } from "../../common/Button";
import { TextNoMargin } from "../../common/Text";
import { SpinnerLoader } from "../../common/SpinningLoader";

const DashboardIcon = styled.span`
  width: 54px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => `
    border-radius: ${props.variant === "contained" ? "10px" : "0"};
    background: ${props.variant === "contained" ? "#6655D1" : "transparent"};
  `}

  & svg {
    ${props => `
    width: ${props.width ? props.width : "unset"};
    height: ${props.height ? props.height : "unset"};
  `}
  }
`;

const dashboardItems = [
  {
    id: "activePoolsPrize",
    description: "Current prize total",
    icon: (
      <DashboardIcon width="42px" height="36.7px">
        <CupIcon />
      </DashboardIcon>
    ),
  },
  {
    id: "activePools",
    description: "Active Tournaments",
    icon: (
      <DashboardIcon variant="contained">
        <GameIcon />
      </DashboardIcon>
    ),
  },
  {
    id: "totalUsers",
    description: "Active users",
    icon: (
      <DashboardIcon variant="contained">
        <ProfileIcon />
      </DashboardIcon>
    ),
  },
  {
    id: "userEarnings",
    description: "User Earnings",
    variant: 3,
    // icon: (
    //   <DashboardIcon variant="contained">
    //     <CircleCheckIcon />
    //   </DashboardIcon>
    // ),
  },
  {
    id: "totalPoolsPrize",
    description: "All-time prizes paid",
    icon: (
      <DashboardIcon>
        <BookIcon />
      </DashboardIcon>
    ),
  },
  {
    id: "totalPools",
    description: "Total Tournaments",
    icon: (
      <DashboardIcon variant="contained">
        <CircleCheckIcon />
      </DashboardIcon>
    ),
  },
];

const Card = styled.div`
  height: 175px;
  border-radius: 10px;
  padding: 16px 18px;
  align-items: center;
  background: #3f2faa;

  &:nth-child(1) {
    background: linear-gradient(249.73deg, #ff9d7e 2.87%, #8c54fd 89.48%);
    margin-bottom: 25px;
  }

  &:nth-child(2) {
    background: url("/assets/bg-active-pools.svg");
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 25px;
  }

  &:nth-child(3) {
    background: url("/assets/bg-active-users.svg");
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 25px;
  }

  &:nth-child(4) {
    background: linear-gradient(249.73deg, #5487f4 2.87%, #8c54fd 89.48%);
    overflow: hidden;
  }

  ${breakpoints.up(breakpoints.laptop)} {
    width: 32%;
    height: 175px;
  }

  ${breakpoints.down(breakpoints.laptop)} {
    width: 47%;
  }

  ${breakpoints.down(breakpoints.mobileM)} {
    padding: 8px 9px;
  }

  ${props => `
    background: ${props.highlight ? "" : ""};
    display: ${props.variant === 2 ? "flex" : "block"};
  `}
`;

const EmptyDiv = styled.div`
  visibility: hidden;
  height: 175px;

  ${breakpoints.up(breakpoints.laptop)} {
    width: 32%;
    height: 175px;
  }

  ${breakpoints.down(breakpoints.laptop)} {
    width: 47%;
  }

  ${breakpoints.down(breakpoints.mobileM)} {
    padding: 8px 9px;
  }
`;
// width: ${props.width ? props.width : "321px"};

const IconWrap = styled.div``;

const CardDescription = styled.p`
  margin-bottom: 0px;
  ${props => `
    margin-top: ${props.variant === 2 ? "0px" : "22px"};
    font-size: ${props.variant === 2 ? "12px" : "14px"};
    line-height: ${props.variant === 2 ? "30px" : "20px"};
  `}
  ${breakpoints.down(breakpoints.laptop)} {
    font-size: 12px;
    line-height: 20px;
  }
`;

const CardValue = styled.p`
  ${props => `
    font-size: ${props.variant === 2 ? "26px" : "40px"};
    line-height: ${props.variant === 2 ? "20px" : "56px"};
  `}

  ${breakpoints.down(breakpoints.laptop)} {
    font-size: 20px;
    line-height: 30px;
  }

  ${breakpoints.down(breakpoints.mobileS)} {
    font-size: 14px;
    line-height: 20px;
  }
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  color: white;
  & .ant-progress-circle .ant-progress-text {
    color: white !important;
    font-size: 15px;
    line-height: 20px;
  }
`;

const TextWrap = styled.div`
  margin-left: 20px;
  ${breakpoints.down(breakpoints.mobileM)} {
    margin-left: 8px;
  }
`;

const CardGroup = ({
  scrollToPoolsSection = () => {},
  isLoggedIn = false,
  userEarnings = 0,
  isLoadingUserEarnings = false,
}) => {
  // const { platformState } = useAppState();
  const [platformState, setPlatformState] = useState({});
  let mounted = useRef(true);

  const getAppState = async () => {
    try {
      const response = await callAxios({
        method: "get",
        url: `${ENDPOINT.APP_STATE}`,
        // headers: JSON.stringify({ accept: "*/*", Authorization: JSON.stringify(headers)}),
      });

      if (response && response.data && mounted) {
        setPlatformState(response.data);
      }
    } catch (err) {
      console.log("error while getting app state", err);
    }
  };

  // Warning not fixed: Assignments to the 'mounted' variable from inside React Hook useEffect will be lost after each render.
  useEffect(() => {
    getAppState();

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      mounted = false;
    };
  }, []);

  const isWider = useMedia({ minWidth: "450px" });

  const formatNumber = val => {
    return new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
    }).format(val);
  };

  const getCurrentValue = item => {
    if (!platformState) {
      return "-";
    }

    const ps = platformState;
    switch (item.id) {
      case "totalUsers":
        return ps.totalUsers !== undefined ? formatNumber(ps.totalUsers) : "-";
      case "activePools":
        return ps.activePools !== undefined ? formatNumber(ps.activePools) : "-";
      case "totalPoolsPrize":
        return ps.totalPoolsPrize !== undefined ? "$ " + formatNumber(ps.totalPoolsPrize) : "-";
      case "activePoolsPrize":
        return ps.activePoolsPrize !== undefined ? "$ " + formatNumber(ps.activePoolsPrize) : "-";
      case "totalPools":
        return ps.totalPools !== undefined ? formatNumber(ps.totalPools) : "-";
      default:
        return "-";
    }
  };

  return (
    <S.Group>
      {dashboardItems.map(item => {
        if (item.variant === 3 && !isLoggedIn) return null;
        return (
          <Card variant={item.variant} key={item.description}>
            {item.variant === 3 ? (
              <CardContent style={{ height: "100%", position: "relative" }}>
                <div style={{ position: "absolute", bottom: "-50px", right: "-20px", opacity: "0.1" }}>
                  <BlastLogoGradient />
                </div>

                <Flex direction="column" justifyContent="space-between" extraStyles={{ width: "100%", height: "100%" }}>
                  <Flex justifyContent="space-between" alignItems="center" extraStyles={{ width: "100%" }}>
                    <TextNoMargin fontSize="20px" fontWeight="325">
                      Your Earnings
                    </TextNoMargin>
                    <Button
                      onClick={scrollToPoolsSection}
                      style={{
                        background: "#ffffff4D",
                        width: "106px",
                        height: "43px",
                        borderRadius: "30px",
                        fontSize: "16px",
                      }}
                    >
                      Claim
                    </Button>
                  </Flex>

                  <div style={{ marginBottom: "22px", display: "flex", flexDirection: "column", gap: "15px" }}>
                    <TextNoMargin fontWeight="325" fontSize="14px" color="#ffffff80">
                      Your Balance
                    </TextNoMargin>
                    <TextNoMargin fontWeight="325" fontSize="38px">
                      {userEarnings && !isLoadingUserEarnings ? (
                        typeof userEarnings === "number" ? (
                          `$${userEarnings?.toFixed(4)}`
                        ) : (
                          `$${userEarnings === "-" ? 0 : userEarnings}`
                        )
                      ) : (
                        <SpinnerLoader style={{ border: "3px solid #ffffff4d", borderBottomColor: "#ffffff" }} />
                      )}
                    </TextNoMargin>
                  </div>
                </Flex>
              </CardContent>
            ) : item.variant === 2 ? (
              <CardContent>
                <Progress
                  type="circle"
                  strokeColor={{
                    "0%": "#12C2EA",
                    "100%": "#FC74FF",
                  }}
                  strokeLinecap="square"
                  trailColor="#6655D1"
                  percent={0}
                  width={isWider ? 90 : 55}
                  gapDegree={0}
                  format={() => "-"}
                />
                <TextWrap>
                  <CardDescription variant={item.variant}>{item.description}</CardDescription>
                  <CardValue variant={item.variant}>
                    {/* {new Intl.NumberFormat("en-US", {
                    style: "decimal",
                    maximumFractionDigits: 2,
                  }).format(0)} */}
                    -
                  </CardValue>
                </TextWrap>
              </CardContent>
            ) : (
              <>
                <IconWrap>{item.icon}</IconWrap>
                <CardDescription>{item.description}</CardDescription>
                <CardValue>{getCurrentValue(item)}</CardValue>
              </>
            )}
          </Card>
        );
      })}

      {!isLoggedIn && <EmptyDiv />}
    </S.Group>
  );
};

export default CardGroup;
