import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import "./index.css";
// import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { ThemeProvider } from "styled-components";
import App from "./App";
import theme from "./themes";
import { GlobalStyle } from "./globalStyle";
import { ActiveMenuProvider } from "./hooks/ActiveMenu";

const prevTheme = window.localStorage.getItem("theme");

const subgraphUri = "http://localhost:8000/subgraphs/name/blastapp/your-contract";

const client = new ApolloClient({
  uri: subgraphUri,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme[prevTheme || "dark"]}>
      <ActiveMenuProvider>
        <GlobalStyle />
        <App subgraphUri={subgraphUri} />
      </ActiveMenuProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("root"),
);
