// BACKEND
export const BE_API_BASE_URL = process.env.REACT_APP_BE_API_BASE_URL;

// FIREBASE
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MSG_SENDER_ID = process.env.REACT_APP_FIREBASE_MSG_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;

// ALGOLIA
export const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY;
export const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID;

// CHAIN
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID ? parseInt(process.env.REACT_APP_CHAIN_ID) : 137;
export const CHAIN_NAME = process.env.REACT_APP_CHAIN;
export const CHAIN_TOKEN_NAME = process.env.REACT_APP_CHAIN_TOKEN_NAME;
export const CHAIN_TOKEN_SYMBOL = process.env.REACT_APP_CHAIN_TOKEN_SYMBOL;
export const CHAIN_TOKEN_DECIMALS = process.env.REACT_APP_CHAIN_TOKEN_DECIMALS;

export const CHAIN_EXPLORER = process.env.REACT_APP_CHAIN_EXPLORER;
export const CHAIN_RPC_URL = process.env.REACT_APP_CHAIN_RPC_URL;

// BLAST CONTRACTS
export const CODCHALLENGES_ADDRESS = process.env.REACT_APP_CODCHALLENGES_ADDRESS;
export const CODCHALLENGESV2_ADDRESS = process.env.REACT_APP_CODCHALLENGESV2_ADDRESS;
export const POOLFACTORYV2_ADDRESS = process.env.REACT_APP_POOLFACTORYV2_ADDRESS;
export const POOLFACTORYKOINS_ADDRESS = process.env.REACT_APP_POOLFACTORYKOINS_ADDRESS;

// INFURA
export const INFURA_ID = process.env.REACT_APP_INFURA_ID;

// CRYPTO
export const SEED = process.env.REACT_APP_CRYPTO_SEED;

// WSS
export const WSS_SERVER_URL = process.env.REACT_APP_WSS_SERVER_URL;
export const WSS_CRYPTO_SEED = process.env.REACT_APP_WSS_CRYPTO_SEED;

// DEFAULT JOIN POOL APPROVE VALUE
export const APPROVE_AMOUNT = process.env.REACT_APP_DEF_APPROVE_VALUE;

// LEADERBOARD ROWS
export const LEADERBOARD_ROWS = process.env.REACT_APP_LEADERBOARD_ROWS;
export const LEADERBOARD_PAGE_LIMIT = process.env.REACT_APP_LEADERBOARD_PAGE_LIMIT;
export const MOBILE_LEADERBOARD_PAGE_LIMIT = process.env.REACT_APP_MOBILE_LEADERBOARD_PAGE_LIMIT;

// COIN MARKET CAP
export const COINMARKETCAP_API_KEY = process.env.REACT_APP_COINMARKETCAP_API_KEY;

// MILLISECONDS FOR CACHING PRICE CONVERSION
export const PRICE_API_CACHE_LIVES = process.env.REACT_APP_PRICE_API_CACHE_LIVES;

// NUMBER OF NOTIFICAITONS TO DISPLAY
export const NOTIFICAITON_QTY = process.env.REACT_APP_NOTIFICAITON_QTY;

export const NOTIFICATION_HOURS = process.env.REACT_APP_NOTIFICATION_HOURS;

// FOR ADD TO CALENDAR
export const GOOGLE_CALENDAR_URL = process.env.REACT_APP_GOOGLE_CALENDAR_URL;
export const OUTLOOK_CALENDAR_URL = process.env.REACT_APP_OUTLOOK_CALENDAR_URL;

// FOR EPHERE
export const EPHERE_REDIRECT_URL = process.env.REACT_APP_EPHERE_REDIRECT_URL

// FOR CLAIM
export const CLAIM_WAIT_PERIOD = process.env.REACT_APP_CLAIM_WAIT_PERIOD || 5000;

export const STATE_KEY = process.env.REACT_APP_STATE_KEY || 'user_state';

export const DISCORD_CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID;  
export const DISCORD_REDIRECT_URI = process.env.REACT_APP_DISCORD_REDIRECT_URI;  
export const DISCORD_ENCRYPTED_VALUE = process.env.REACT_APP_DISCORD_ENCRYPTED_VALUE;
export const DISCORD_AUTHORIZE_URL = process.env.REACT_APP_DISCORD_AUTHORIZE_URL;


export const WALLET_CONNECT_ID = process.env.REACT_APP_WALLET_CONNECT_ID;
export const ANALYTICS_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;