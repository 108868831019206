import React, { useEffect, useState } from "react";
// import { useCookies } from "react-cookie";
import { RecoilRoot } from "recoil";
import { HashRouter, Switch, Route } from "react-router-dom";
import "antd/dist/antd.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import styled from "styled-components";
import ReactGA from "react-ga4";
import { AppStateProvider } from "./providers/AppStateProvider";
import { WalletProvider } from "./providers/WalletProvider";
import Routes from "./views/Routes";
import ErrorPage from "./views/ErrorPage";
import WindowFocusHandler from "./views/general/WindowFocusHandler";
import { CrossAlt } from "./assets/svg";
import TournamentBracketsView from "./views/TournamentBracketsView";
import CustomQueryClientProvider from "./QueryWrapper";
import { ANALYTICS_TRACKING_ID } from "./constants/env";

require("dotenv").config();

const AppWrapper = styled.div`
  background: white;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;

  & .image-hover-opacity:hover {
    opacity: 0.8;
  }

  & .image-hover-opacity {
    opacity: 0.5;
    cursor: pointer;
  }
`;

// const CookieBanner = styled.div`
//   position: fixed;
//   bottom: 0;
//   background: #000;
//   color: #fff;
//   z-index: 9999;
//   height: fit-content;
//   min-height: 69px;
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   font-size: 14px;
//   font-weight: 325;
//   line-height: 16px;
//   padding-left: 42px;
//   padding-right: 42px;
//   a {
//     text-decoration: underline;
//     color: #fff;
//     margin-left: 6px;
//   }
//   div span {
//     margin-right: 24px;
//   }
//   button {
//     width: 104px;
//     height: 32px;
//     background: linear-gradient(249.73deg, #5487f4 2.87%, #8c54fd 89.48%);
//     border-radius: 6px;
//     cursor: pointer;
//   }
//   svg {
//     cursor: pointer;
//   }
//   @media (max-width: 1200px) {
//     align-items: flex-start;
//     padding-left: 12px;
//     padding-right: 12px;
//     svg {
//       padding-top: 8px;
//     }
//   }
// `;

// const BannerContent = styled.div`
//   display: flex;
//   align-items: center;
//   @media (max-width: 1200px) {
//     flex-direction: column;
//     align-items: flex-start;
//     padding: 8px 0;
//     button {
//       margin: 0;
//       padding: 0;
//       margin-top: 16px;
//     }
//   }
// `;

function App() {
  // const [showCookieBanner, setShowCookieBanner] = useState(false);
  // const [cookies, setCookie] = useCookies(["user-accepted"]);
  // useEffect(() => {
  //   if (!cookies.user_accepted) {
  //     setShowCookieBanner(true);
  //   }
  // }, []);
  // const hideCookieBanner = () => {
  //   setShowCookieBanner(false);
  // };
  // const acceptCookies = () => {
  //   setCookie("user_accepted", true, {
  //     expires: new Date(2147483647 * 1000),
  //   });
  //   setShowCookieBanner(false);
  // };

  const initGA = () => {
    if (process.env.NODE_ENV === "production" && ANALYTICS_TRACKING_ID) {
      ReactGA.initialize(ANALYTICS_TRACKING_ID);
    }
  };

  useEffect(() => {
    initGA();
  }, []);

  return (
    <RecoilRoot>
      <HashRouter>
        <WalletProvider>
          {/* <ActivityProvider> */}
          <WindowFocusHandler />
          <AppStateProvider>
            <AppWrapper>
              {/* {showCookieBanner && (
              <CookieBanner>
                <BannerContent>
                  <span>
                    We use cookies to ensure that we give you the best experience on our website. If you continue, we
                    will assume that you are happy with it.
                    <a href="https://blast.gg/privacy" rel="noopener noreferrer" target="_blank">
                      Privacy Policy.
                    </a>
                  </span>

                  <button type="button" onClick={acceptCookies}>
                    Accept
                  </button>
                </BannerContent>
                <div>
                  <CrossAlt onClick={hideCookieBanner} />
                </div>
              </CookieBanner>
            )} */}
              <CustomQueryClientProvider>
                <Switch>
                  <Route exact path="/404" component={ErrorPage} />
                  <Route
                    exact
                    path="/brackets/pool/:id"
                    component={() => (
                      <div style={{ display: "flex", justifyContent: "center", background: "#272054", width: "100vw" }}>
                        <TournamentBracketsView />
                      </div>
                    )}
                  />
                  {/* <Route exact path="*" component={LaunchPage} /> */}
                  <Routes />
                </Switch>
              </CustomQueryClientProvider>
            </AppWrapper>
          </AppStateProvider>
          {/* </ActivityProvider> */}
        </WalletProvider>
      </HashRouter>
    </RecoilRoot>
  );
}

export default App;
