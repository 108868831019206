import React, { useEffect, useRef, useState } from "react";
import * as crypto from "crypto-js";
import { useRecoilState } from "recoil";
import * as S from "./styles";
import { sessionState } from "../../state/globalState";
import Loader from "../../components/v2/Loader";
import { DottedMenu, LeftVector, RightVector } from "../../assets/svg";
import adImg from "../../assets/tournament-list-page-ad.png";
import useNotifMethods from "../../hooks/useNotifMethods";
import { useGetSecHeaders } from "../../hooks/SecurityHeaders";
import NotificationsList from "./NotificationsList";
import { NOTIFICAITON_QTY, SEED } from "../../constants/env";
import MobileSocialNav from "../../components/v2/common/MobileSocialNav";
import { setUserInLocalStorage } from "../../helpers/localStorage";

export default function MessagePage() {
  const dotMenuRef = useRef({});
  const typeArray = ["Notifications"];
  const [notifType, setNotifType] = useState(typeArray[0]);
  const [showDotMenu, setShowDotMenu] = useState(false);
  const [totalNotifLength, setTotalNotifLength] = useState(0);
  // states for url params
  const [endBefore, setEndBefore] = useState(0);
  const [startAfter, setStartAfter] = useState(0);
  const [qty] = useState(NOTIFICAITON_QTY || 5);
  const [showOnlyUnread, setShowOnlyUnread] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [isFetchingNotifications, setIsFetchingNotifications] = useState(false);
  const headers = useGetSecHeaders();

  const { markAsUnread, fetchNotifications, deleteNotification } = useNotifMethods();
  const [session, setSession] = useRecoilState(sessionState);

  const resetPagination = () => {
    setStartAfter(0);
    setEndBefore(0);
    setCurrentPage(1);
  };
  const fetchFunction = async () => {
    setIsFetchingNotifications(true);
    if (headers.token) {
      const res = await fetchNotifications({
        startAfter,
        endBefore,
        qty,
        unread: showOnlyUnread,
      });
      setNotifications(res.messages);
      setTotalNotifLength(res.total);
      setIsFetchingNotifications(false);
    }
  };

  // add fetchFunction is no possible due to infinite loop of notifications
  useEffect(() => {
    fetchFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers.token, startAfter, endBefore, showOnlyUnread, qty]);

  const deleteSelected = async () => {
    setShowDotMenu(false);
    setIsFetchingNotifications(true);
    const temp = [...selectedNotifications];
    setSelectedNotifications([]);
    for (let index = 0; index < temp.length; index += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await deleteNotification(selectedNotifications[index]);
      } catch (err) {
        console.log(err);
      }
    }
    if (startAfter || endBefore || currentPage !== 1) {
      resetPagination();
    } else {
      fetchFunction();
    }
  };

  const markSelectedAsUnread = async () => {
    const temp = [...selectedNotifications];
    setShowDotMenu(false);
    setSelectedNotifications([]);
    setIsFetchingNotifications(true);
    for (let index = 0; index < temp.length; index += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await markAsUnread(selectedNotifications[index]);
      } catch (err) {
        console.log(err);
      }
    }
    const {
      user: { unreadMessages },
    } = session;

    const updatedSession = {
      ...session,
      user: {
        ...session.user,
        unreadMessages: unreadMessages + temp.length,
      },
    };
    setSession(updatedSession);
    const encryptedState = crypto.AES.encrypt(JSON.stringify(updatedSession), SEED).toString();
    setUserInLocalStorage(encryptedState);
    if (startAfter || endBefore || currentPage !== 1) {
      resetPagination();
    } else {
      fetchFunction();
    }
  };
  const toggleDotMenu = () => {
    setShowDotMenu(prevState => !prevState);
  };
  const toggleShowUnreadNotif = () => {
    setShowOnlyUnread(prev => !prev);
    setSelectedNotifications([]);
    setShowDotMenu(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <S.AdWrapper>
        <S.AdTextWrapper>
          <p>It&apos;s time to show what</p>
          <p>
            you are <span>made of</span>
          </p>
        </S.AdTextWrapper>
        <img src={adImg} alt="" />
      </S.AdWrapper>
      <S.SectionContainer>
        <S.HeaderTitle>Messages</S.HeaderTitle>
        <S.TopMenu>
          <S.FilterWrapper>
            {typeArray.map(item => (
              <S.FilterTitle key={item} active={notifType === item} onClick={() => setNotifType(item)}>
                {item}
              </S.FilterTitle>
            ))}
          </S.FilterWrapper>
          <S.DottedMenuCotnainer ref={dotMenuRef}>
            <S.DottedMenuButton onClick={toggleDotMenu}>
              <DottedMenu />
            </S.DottedMenuButton>
            {showDotMenu && (
              <S.MenuContent>
                <button type="button" onClick={toggleShowUnreadNotif}>
                  {showOnlyUnread ? "Show Read Messages" : "Hide Read Messages"}
                </button>
                {selectedNotifications.length > 0 && (
                  <>
                    <button type="button" onClick={deleteSelected}>
                      Delete Selected
                    </button>
                    <button type="button" onClick={markSelectedAsUnread}>
                      Mark As Unread
                    </button>
                  </>
                )}
              </S.MenuContent>
            )}
          </S.DottedMenuCotnainer>
        </S.TopMenu>
        <S.NotificationContainer>
          {!notifications || isFetchingNotifications ? (
            <S.LoaderWrapper>
              <Loader animation="rotate" />
            </S.LoaderWrapper>
          ) : (
            <NotificationsList
              list={notifications}
              selectedNotifications={selectedNotifications}
              setSelectedNotifications={setSelectedNotifications}
            />
          )}
          <S.ButtonContainer>
            <S.Arrow
              type="button"
              onClick={() => {
                if (currentPage > 1) {
                  setStartAfter(0);
                  setEndBefore(notifications[0].createdOn);
                  setCurrentPage(prev => prev - 1);
                }
              }}
              disabled={isFetchingNotifications || !currentPage > 1 || notifications.length < 1}
            >
              <LeftVector />
            </S.Arrow>
            <p>
              {totalNotifLength <= 0 || isFetchingNotifications
                ? "-"
                : `${currentPage} / ${Math.ceil(totalNotifLength / qty)}`}
            </p>
            <S.Arrow
              type="button"
              onClick={() => {
                if (currentPage < totalNotifLength / qty) {
                  setEndBefore(0);
                  setStartAfter(notifications[notifications.length - 1].createdOn);
                  setCurrentPage(prev => prev + 1);
                }
              }}
              disabled={isFetchingNotifications || (notifications && notifications.length < 1)}
            >
              <RightVector />
            </S.Arrow>
          </S.ButtonContainer>
        </S.NotificationContainer>
        <MobileSocialNav />
      </S.SectionContainer>
    </div>
  );
}
